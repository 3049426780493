.app-main-container {
  cursor: default;
  background-color: #07080b;
}

// GENERAL

.redirect-link {
  text-decoration: none;
}

.gradient {
  background-image: linear-gradient(82deg, #f80 18%, #ffe600 85%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

// NAVBAR

.navbar-main-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 30px;
  background-image: linear-gradient(to bottom, #07080b, rgba(0, 0, 0, 0.15));
  position: sticky;
  top: 0;
  z-index: 2;
}

@media (max-width: 1199px) {
  .navbar-main-container {
    padding: 12px 30px;
  }
}

@media (max-width: 500px) {
  .navbar-main-container {
    padding: 8px 16px;
  }
}

.navbar-torum-logo-container {
  display: flex;
  align-items: center;
  width: 237px;
}

.navbar-item-list-container {
  display: flex;
}

@media (max-width: 1199px) {
  .navbar-item-list-container {
    display: none;
  }
}

.navbar-item {
  cursor: pointer;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 600;
  color: #c2c2c2;
  padding: 20px;
  transition: all 0.2s ease-in-out;

  &:hover {
    filter: brightness(140%);
  }
}

.navbar-button-container {
  display: flex;
  align-items: center;
}

.navbar-button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 30px;
  border-radius: 100px;
  font-weight: 600;
  font-size: 16px;
  transition: all 0.2s ease-in-out;

  &:hover {
    filter: brightness(130%);
  }

  &.apply {
    margin-right: 20px;
    color: #000;
    background-image: linear-gradient(77deg, #f80, #ffe600);
  }

  &.intro {
    background-image: linear-gradient(to left, #2196f3, #682be6);
  }
}

@media (max-width: 650px) {
  .navbar-button {
    padding: 6px 30px;

    &.apply {
      margin-right: 10px;
    }
  }
}

// HERO

#hero-video {
  position: relative;
  z-index: 0;
  width: 100%;
  height: 110vh;
  object-fit: cover;
  margin-top: -85px;
}

.hero-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100vh;
  margin-top: -85px;
}

@media (max-width: 374px) {
  .hero-main-container {
    margin-top: 0px;
  }
}

.hero-main-content-container {
  text-align: center;
  max-width: 992px;
  margin: 95px 60px 0;
}

@media (max-width: 630px) {
  .hero-main-content-container {
    margin-top: 0px;
    margin: 95px 30px 0;
  }
}

@media (max-width: 475px) {
  .hero-main-content-container {
    margin-top: 0px;
    margin: 95px 20px 0;
  }
}

@media (max-width: 380px) {
  .hero-main-content-container {
    margin-top: 0px;
    margin: 55px 20px 0;
  }
}

.hero-title {
  font-size: 48px;
  font-weight: 700;
  line-height: 1.7;
  margin-top: 24px;
  text-shadow: 2px 2px 8px #000, -2px -2px 8px #000;
}

@media (max-width: 1375px) {
  .hero-title {
    font-size: 43px;
  }
}

@media (max-width: 800px) {
  .hero-title {
    font-size: 36px;
  }
}

@media (max-width: 600px) {
  .hero-title {
    font-size: 32px;
  }
}

@media (max-width: 560px) {
  .hero-title {
    width: 280px;
    margin: auto;
    font-size: 36px;
  }
}

@media (max-width: 475px) {
  .hero-title {
    font-size: 34px;
  }
}

.hero-description {
  font-size: 24px;
  font-weight: 500;
  line-height: 2;
  margin-top: 24px;
  text-shadow: 2px 2px 8px #000, -2px -2px 8px #000;
}

@media (max-width: 1375px) {
  .hero-description {
    font-size: 22px;
  }
}

@media (max-width: 800px) {
  .hero-description {
    font-size: 19px;
  }
}

@media (max-width: 600px) {
  .hero-description {
    font-size: 17px;
  }
}

// BACKGROUND

.background-main-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1376px;
  margin: auto;
  padding: 160px 80px;
}

@media (max-width: 1375px) {
  .background-main-container {
    padding: 120px 60px;
    max-width: 1040px;
  }
}

@media (max-width: 999px) {
  .background-main-container {
    &.background {
      background-image: url("./assets/torum-logo-3d-transparent.png");
      background-repeat: no-repeat;
      background-position: center center;
    }

    &.about {
      background-image: url("./assets/torum-labs-logo-3d-transparent.png");
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
}

@media (max-width: 600px) {
  .background-main-container {
    padding: 100px 30px;
  }
}

@media (max-width: 475px) {
  .background-main-container {
    padding: 80px 26px;
  }
}

@media (max-width: 1375px) {
  .background-logo {
    width: 260px;
  }
}

@media (max-width: 999px) {
  .background-logo {
    display: none;
  }
}

.background-text-container {
  max-width: 700px;
}

@media (max-width: 1375px) {
  .background-text-container {
    max-width: 550px;
  }
}

@media (max-width: 999px) {
  .background-text-container {
    max-width: inherit;
  }
}

.section-title {
  font-size: 36px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 42px;
}

@media (max-width: 1375px) {
  .section-title {
    font-size: 32px;
  }
}

@media (max-width: 500px) {
  .section-title {
    font-size: 30px;
  }
}

@media (max-width: 400px) {
  .section-title {
    font-size: 26px;
  }
}

.background-description {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.9;
  margin: auto;
  margin-top: 36px;
}

@media (max-width: 1375px) {
  .background-description {
    font-size: 16px;
  }
}

@media (max-width: 999px) {
  .background-description {
    text-shadow: 2px 2px 8px #000, -2px -2px 8px #000;
  }
}

@media (max-width: 800px) {
  .background-description {
    font-size: 15px;
  }
}

@media (max-width: 620px) {
  .background-description {
    line-height: 1.9;
  }
}

@media (max-width: 379px) {
  .background-description {
    font-size: 14px;
  }
}

// PORTFOLIO

.portfolio-main-container {
  max-width: 1420px;
  margin: auto;
  text-align: center;
  margin-top: 60px;
  margin-bottom: 30px;
  padding: 166px 40px;
}

@media (max-width: 630px) {
  .portfolio-main-container {
    padding: 110px 10px;
  }
}

@media (max-width: 475px) {
  .portfolio-main-container {
    padding: 30px 0px;
  }
}

.portfolio-item-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 64px;
}

// PORTFOLIO ITEM

.portfolio-item-wrapper {
  cursor: pointer;
  border-radius: 16px;
  background-color: #141820;
  padding: 30px;
  width: 258px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin: 16px;
  text-decoration: none;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: #181d26;
    transform: scale(1.02);
  }
}

.portfolio-item-image {
  height: 52px;
  object-fit: cover;
}

.portfolio-item-name {
  margin-top: 20px;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.7;
}

.portfolio-item-description {
  margin-top: 10px;
  line-height: 1.6;
  max-height: 77px;
  text-align: left;
  font-size: 16px;
}

@media (max-width: 680px) {
  .portfolio-item-wrapper {
    padding: 24px;
    width: 228px;
    margin: 12px;
  }
  .portfolio-item-name {
    font-size: 18px;
  }
  .portfolio-item-description {
    font-size: 15px;
  }
}

// THIS IS FOR SINGLE ITEM PER ROW

@media (max-width: 630px) {
  .portfolio-item-wrapper {
    padding: 30px;
    width: 258px;
    margin: 16px;
  }

  .portfolio-item-name {
    margin-top: 14px;
    font-size: 17px;
    line-height: 1.8;
  }
  .portfolio-item-description {
    margin-top: 8px;
    font-size: 14px;
    line-height: 1.7;
  }
}

// THIS IS FOR TWO ITEMS PER ROW

// @media (max-width: 580px) {
//   .portfolio-item-wrapper {
//     padding: 18px;
//     width: 208px;
//     margin: 10px;
//   }
//   .portfolio-item-name {
//     margin-top: 16px;
//     font-size: 17px;
//     line-height: 1.8;
//   }
//   .portfolio-item-description {
//     margin-top: 8px;
//     font-size: 14px;
//     line-height: 1.7;
//   }
// }

// @media (max-width: 499px) {
//   .portfolio-item-wrapper {
//     padding: 18px;
//     width: 198px;
//     margin: 10px;
//   }
//   .portfolio-item-name {
//     margin-top: 14px;
//     font-size: 16px;
//     line-height: 1.8;
//   }
//   .portfolio-item-description {
//     margin-top: 8px;
//     font-size: 13px;
//     line-height: 1.7;
//   }
// }

// @media (max-width: 474px) {
//   .portfolio-item-wrapper {
//     padding: 30px;
//     width: 258px;
//     margin: 16px;
//   }

//   .portfolio-item-name {
//     margin-top: 14px;
//     font-size: 17px;
//     line-height: 1.8;
//   }
//   .portfolio-item-description {
//     margin-top: 8px;
//     font-size: 14px;
//     line-height: 1.7;
//   }
// }

// APPLY

.apply-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 200px 60px;
}

@media (max-width: 630px) {
  .apply-main-container {
    padding: 200px 30px;
  }
}

@media (max-width: 475px) {
  .apply-main-container {
    padding: 200px 20px;
  }
}

.apply-description {
  font-size: 22px;
  margin-bottom: 48px;
  text-align: center;
  line-height: 1.7;
}

@media (max-width: 700px) {
  .apply-description {
    font-size: 20px;
  }
}

@media (max-width: 475px) {
  .apply-description {
    max-width: 300px;
    font-size: 19px;
  }
}

@media (max-width: 400px) {
  .apply-description {
    font-size: 17px;
  }
}

.apply-button-container {
  text-decoration: none;
}

.apply-button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  color: #000;
  background-image: linear-gradient(77deg, #f80, #ffe600);
  width: 240px;
  height: 54px;
  font-size: 20px;
  font-weight: 600;
  transition: all 0.2s ease-in-out;

  &:hover {
    filter: brightness(130%);
  }
}

@media (max-width: 650px) {
  .apply-button {
    width: 220px;
    height: 49px;
    font-size: 18px;
  }
}

// SCROLL TO TOP ICON

.scroll-to-top-container {
  display: flex;
  justify-content: center;
}

.scroll-to-top-icon {
  cursor: pointer;
  margin-top: 120px;

  transition: all 0.2s ease-in-out;

  &:hover {
    filter: brightness(150%);
  }
}

@media (max-width: 500px) {
  .scroll-to-top-icon {
    margin-top: 60px;
  }
}

// FOOTER

.footer-container {
  font-size: 16px;
  padding: 30px 12px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media (max-width: 500px) {
  .footer-container {
    font-size: 14px;
  }
}

@media (max-width: 370px) {
  .footer-container {
    font-size: 12px;
  }
}
